import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/logo.svg';

export default function Header({ loggedIn, email, onLogout }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);

  const path = location.pathname === '/sign-in' ? '/sign-up' : '/sign-in';

  function onSignOut() {
    navigate('/sign-in', { replace: 'true' });
    setMenuActive(false);
    onLogout();
  }

  function handleMenuClick() {
    setMenuActive(!menuActive);
  }

  return (
    <header
      className={`header page__container ${menuActive ? `header_active` : ''} ${
        loggedIn ? `header_login` : ''
      }`}
    >
    <Link className='header__link' to='/'>
        <img className='header__logo' src={logo} alt='Моя Семья' />
      </Link>
   
    </header>
  );
}
